<template>
  <b-card
    no-body
    class="card-payment"
  >
    <b-card-header>
      <b-card-title>Teacher Details</b-card-title>
      <b-card-title class="text-primary">
        
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      
        <b-row>
            <b-col cols="4">
                <b-form-group
                    id="fieldset-1"
                    label-size="lg"
                    label-class="font-weight-bold"
                    label="Username"
                    label-for="input-1"
                >
                    <p class="form-data">{{user.name}}</p>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group
                    id="fieldset-1"
                    label-class="font-weight-bold"
                    label-size="lg"
                    label="Name"
                    label-for="input-1"
                >
                    <p class="form-data" v-if="user.user_detail">{{user.user_detail.name}}</p>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group
                    id="fieldset-1"
                    label-class="font-weight-bold"
                    label-size="lg"
                    label="Surname"
                    label-for="input-1"
                >
                    <p class="form-data" v-if="user.user_detail">{{user.user_detail.surname}}</p>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group
                    id="fieldset-1"
                    label-size="lg"
                    label-class="font-weight-bold"
                    label="Email"
                    label-for="input-1"
                >
                        <p class="form-data">{{user.email}}</p>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group
                    id="fieldset-1"
                    label-class="font-weight-bold"
                    label-size="lg"
                    label="Telephone Number"
                    label-for="input-1"
                >
                    <p class="form-data" v-if="user.user_detail">{{user.user_detail.telephone_num}}</p>
                </b-form-group>
            </b-col>           
        </b-row>
      
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import UserService from '@/services/user.service.js';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    Cleave,
    BButton,
  },
  
  data() {
    return {
      user: {},
      id: this.$route.params.id,
    }
  },
  watch:{
    '$route'(to) {
      this.id = to.params.id;
    } 
  },
  methods: {
    getUser()
    {
        UserService.find(this.id).then(response => {  
            this.user = response.data.data.user
        }).catch(error => {
            console.log(error)
        });
    }
  },
  created() {
    this.getUser();
  }
}
</script>
